import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $config: Record<string, unknown>;
  }
}

const app = createApp(App).use(router);
app.mount("#app");
