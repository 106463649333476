import Plotly, { Layout } from "plotly.js-dist-min";
import { welch, spectrogram } from "spectral-analysis";
import { getColour, PlotableData } from "@/helpers/helpers";

const plot = async (sensorData: PlotableData[]) => {
  const layout = {
    autosize: true,
    width: window.innerWidth * 0.9,
    height: window.innerHeight * 0.7,
    xaxis: {
      title: {
        text: "Time",
      },
      automargin: true,
    },
    yaxis: {
      title: {
        text: "Acceleration (g)",
      },
    },
  } as Partial<Layout>;

  const dataToPlot = sensorData.map(async ({ SensorName, data }) => {
    // Make an Instant (date) object from the start time string. Convert this into a zoned date time object
    // with the user's chosen timezone. Strip out the timezone, and put in the browser's timezone as this
    // is what plotly (and the Date object) uses.
    return [
      {
        x: data.X.x,
        y: data.X.y,
        type: "scatter",
        name: `${SensorName} X`,
        line: { color: await getColour(`${SensorName} X`) },
      },
      {
        x: data.Y.x,
        y: data.Y.y,
        type: "scatter",
        name: `${SensorName} Y`,
        line: { color: await getColour(`${SensorName} Y`) },
      },
      {
        x: data.Z.x,
        y: data.Z.y,
        type: "scatter",
        name: `${SensorName} Z`,
        line: { color: await getColour(`${SensorName} Z`) },
      },
    ];
  });

  const withoutPromises = await Promise.all(dataToPlot);

  const reduced = withoutPromises.flat();

  const data = reduced as Plotly.Data[];
  const graph = document.getElementById("graph");

  if (graph == null) {
    return;
  }

  Plotly.newPlot(graph, data, layout);
};

const getSpectrogramPlot = (
  burstData: PlotableData,
  axis: "X" | "Y" | "Z",
  samplesPerWindow = 1024,
  overlap = 0.5
): [Plotly.Data[], Partial<Layout>] => {
  const [frequency, windows] = spectrogram(
    burstData.data[axis].y,
    burstData.SampleRate,
    samplesPerWindow,
    overlap
  );
  const psd = [
    {
      y: frequency,
      z: windows,
      type: "heatmap",
      colorscale: [
        [0, "#FFFFFF"],
        [0.0015, "white"],
        [0.0016, "blue"],
        [0.01, "orange"],
        [0.5, "red"],
        [1, "#000000"],
      ],
    },
  ] as Plotly.Data[];
  const layout: Partial<Layout> = {
    autosize: true,
    xaxis: {
      type: "linear",
      autorange: true,
      showticklabels: false,
      showgrid: false,
      ticks: "",
    },
    yaxis: {
      title: "Frequency (Hz)",
      type: "linear",
      autorange: false,
      range: [0, 5],
    },
  };
  return [psd, layout];
};

const plotPSD = async (
  sensorData: PlotableData[],
  scale: "log" | "linear" = "linear",
  elementID: string,
  samplesPerWindow = 1024,
  overlap = 0.5
) => {
  const dataToPlot = sensorData.map(
    async ({ SensorName, SampleRate, data }) => {
      const [psdXx, psdXy] = welch(
        data.X.y,
        SampleRate,
        samplesPerWindow,
        overlap
      );
      const [psdYx, psdYy] = welch(
        data.Y.y,
        SampleRate,
        samplesPerWindow,
        overlap
      );
      const [psdZx, psdZy] = welch(
        data.Z.y,
        SampleRate,
        samplesPerWindow,
        overlap
      );
      const psd = [
        {
          x: psdXx,
          y: psdXy,
          type: "scatter",
          name: `${SensorName} X`,
          line: { color: await getColour(`${SensorName} X`) },
        },
        {
          x: psdYx,
          y: psdYy,
          type: "scatter",
          name: `${SensorName} Y`,
          line: { color: await getColour(`${SensorName} Y`) },
        },
        {
          x: psdZx,
          y: psdZy,
          type: "scatter",
          name: `${SensorName} Z`,
          line: { color: await getColour(`${SensorName} Z`) },
        },
      ];
      return psd;
    }
  );

  const withoutPromises = await Promise.all(dataToPlot);

  const reduced = withoutPromises.flat();

  const data = reduced as Plotly.Data[];

  const layout: Partial<Layout> = {
    autosize: true,
    xaxis: {
      title: "Frequency (Hz)",
      type: "linear",
      autorange: false,
      range: [0, 5],
    },

    yaxis: {
      title: "Power Spectral Density (g²/Hz)",
      type: scale,
      autorange: true,
    },
  };

  const graph = document.getElementById(elementID);

  if (graph == null) {
    return;
  }
  Plotly.newPlot(graph, data, layout);
};

const plotSpectrogram = (
  sensorData: PlotableData,
  axis: "X" | "Y" | "Z",
  elementID: string,
  samplesPerWindow = 1024,
  overlap = 0.5
) => {
  const [trace, layout] = getSpectrogramPlot(
    sensorData,
    axis,
    samplesPerWindow,
    overlap
  );

  const graph = document.getElementById(elementID);

  if (graph == null) {
    return;
  }
  Plotly.newPlot(graph, trace, layout);
};

export { plotPSD, plot, plotSpectrogram };
