import { EventOrigin } from "@/interfaces/EventOrigin";
import { Event } from "@/interfaces/Event";
import { Incident } from "@/interfaces/Incident";
import { Sensor, SensorWithLatestAndBurst } from "@/interfaces/Sensor";
import { Data } from "@/interfaces/Data";
import { Burst, SingleBurst } from "@/interfaces/Burst";
import { Aggregator } from "@/interfaces/Aggregator";
import { Task } from "@/interfaces/Task";
import { TaskRule } from "@/interfaces/TaskRule";
import { TaskRun } from "@/interfaces/TaskRun";
import { BalenaDevice } from "@/interfaces/Balena";
import { Temporal } from "@js-temporal/polyfill";
import { ServiceConfiguration } from "@/interfaces/ServiceConfiguration";
import { GrafanaPreferences } from "@/interfaces/GrafanaPreferences";
import { getAuthToken } from "./store";
import { InvalidCredentialsError, PermissionsError } from "@/errors/errors";

const fetchWithToken = async (
  location: string,
  init: RequestInit = { headers: {} }
): Promise<Response> => {
  const initWithAuth = {
    ...init,
    headers: { ...init.headers, Authorization: `Bearer ${getAuthToken()}` },
  };
  const response = await fetch(location, initWithAuth);
  if (response.status === 401) {
    // Redirect to login page - it'd be nice to tell them at some point
    if (window.location.pathname != "/login") {
      window.location.assign("/login");
    }
  } else if (response.status === 403) {
    //Tell them they don't have permission and stop operation
    throw new PermissionsError(
      "You do not have permission to perform that operation"
    );
  }
  return response;
};

const fetchOrigins = async (): Promise<EventOrigin[]> => {
  const origins = await fetchWithToken("/api/v1/event_origins");
  return origins.json();
};

const fetchEvents = async (params: URLSearchParams): Promise<Event[]> => {
  const incidents = await fetchWithToken(`/api/v1/events?${params.toString()}`);
  return incidents.json();
};

const fetchIncidents = async (params: URLSearchParams): Promise<Incident[]> => {
  const incidents = await fetchWithToken(
    `/api/v1/incidents?${params.toString()}`
  );
  return incidents.json();
};

const fetchSensors = async (): Promise<Sensor[]> => {
  const sensors = await fetchWithToken("/api/v1/sensors");
  return sensors.json();
};

const fetchSensorsWithLatestSample = async (): Promise<
  SensorWithLatestAndBurst[]
> => {
  const sensors = await fetchWithToken(
    "/api/v1/sensors?include_latest_sample_and_burst"
  );
  return sensors.json();
};

const fetchData = async (start_time: Temporal.Instant): Promise<Data[]> => {
  const data = await fetchWithToken(
    `/api/v1/data/data?Limit=1000&StartTime=${start_time.epochSeconds}`
  );
  return data.json();
  // NB: at the moment the API will return the most recent 1000 bursts and the earliest 1000 data samples in the specified period!
};

const fetchBursts = async (params: URLSearchParams): Promise<Burst[]> => {
  const bursts = await fetchWithToken(
    `/api/v1/data/bursts?${params.toString()}`
  );
  return bursts.json();
};

const fetchAggregators = async (): Promise<Aggregator[]> => {
  const aggregators = await fetchWithToken("/api/v1/aggregators");
  return aggregators.json();
};

const fetchBurst = async (burstId: string): Promise<SingleBurst> => {
  const burst = await fetchWithToken(`/api/v1/data/burst/${burstId}`);
  return burst.json();
};

const fetchTasks = async (): Promise<Task[]> => {
  const tasks = await fetchWithToken("/api/v1/tasks");
  return tasks.json();
};

const fetchTaskRules = async (): Promise<TaskRule[]> => {
  const taskRule = await fetchWithToken("/api/v1/task_rules");
  return taskRule.json();
};

const fetchTaskRuns = async (params: URLSearchParams): Promise<TaskRun[]> => {
  const taskRuns = await fetchWithToken(`/api/v1/task_runs?${params.toString()}`);
  return taskRuns.json();
};

const fetchTaskRunCounts = async (params: URLSearchParams): Promise<Record<number, number>> => {
  const taskRuns = await fetchWithToken(`/api/v1/count_task_runs_by_rule?${params.toString()}`);
  return taskRuns.json();
};

const fetchBalenaDevices = async (): Promise<BalenaDevice[]> => {
  const origins = await fetchWithToken("/api/v1/balena/devices");
  return origins.json();
};

// Returns the TOML file (not base64 encoded)
const fetchBalenaAggregatorConfig = async (deviceUUID: string): Promise<string> => {
  const origins = await fetchWithToken(`/api/v1/balena/device/${deviceUUID}/config.toml`);
  return origins.text();
};

// POSTs config file, currently in TOML format.  Do not base-64 encode it.
const updateBalenaAggregatorConfig = async (deviceUUID: string, conf: string): Promise<void> => {
  await fetchWithToken(`/api/v1/balena/device/${deviceUUID}/config.toml`, {
    method: "POST",
    body: conf,
    headers: {
      "Content-Type": "application/toml",
    },
  });
};

const fetchServiceConfiguration = async (): Promise<ServiceConfiguration> => {
  const serviceConfiguration = await fetchWithToken(
    "/api/v1/service_configuration"
  );
  return serviceConfiguration.json();
};

const fetchGrafanaPreferences = async (): Promise<GrafanaPreferences> => {
  const grafanaPreferences = await fetch("/grafana/api/user/preferences");
  return grafanaPreferences.json();
};

const postRerunTask = async (taskID: number): Promise<void> => {
  await fetchWithToken(`/api/v1/rerun_task/${taskID}`, {
    method: "POST",
  });
};

const postNewTask = async (rule: TaskRule): Promise<Response> => {
  const response = await fetchWithToken("/api/v1/new_task_rule", {
    method: "POST",
    body: JSON.stringify(rule),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error(await response.json());
  }
  return response.json();
};

const postUpdateTask = async (
  rule: TaskRule,
  taskRuleID: number
): Promise<Response> => {
  const response = await fetchWithToken(
    `/api/v1/update_task_rule/${taskRuleID}`,
    {
      method: "POST",
      body: JSON.stringify(rule),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.json();
};

const putUpdateGrafanaPreferences = async (
  preferences: GrafanaPreferences
): Promise<Response> => {
  const response = await fetch("/grafana/api/user/preferences", {
    method: "PUT",
    body: JSON.stringify(preferences),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.json();
};

interface AuthResponse extends Response {
  Message: string;
  AuthToken: string;
}

const postLogin = async (
  username: string,
  password: string
): Promise<AuthResponse> => {
  const response = await fetch("/auth/login", {
    method: "POST",
    body: JSON.stringify({
      Username: username,
      Password: password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 401) {
    throw new InvalidCredentialsError("Invalid Credentials");
  }
  return response.json();
};

export {
  fetchEvents,
  fetchOrigins,
  fetchIncidents,
  fetchSensors,
  fetchSensorsWithLatestSample,
  fetchData,
  fetchBursts,
  fetchAggregators,
  fetchBurst,
  fetchTasks,
  fetchTaskRules,
  fetchTaskRuns,
  fetchTaskRunCounts,
  fetchBalenaDevices,
  fetchBalenaAggregatorConfig,
  updateBalenaAggregatorConfig,
  fetchServiceConfiguration,
  fetchGrafanaPreferences,
  postRerunTask,
  postNewTask,
  postUpdateTask,
  putUpdateGrafanaPreferences,
  postLogin,
};
