class InvalidCredentialsError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, InvalidCredentialsError.prototype);
  }
}

class PermissionsError extends Error {
  constructor(msg: string) {
    super(msg);
    Object.setPrototypeOf(this, PermissionsError.prototype);
  }
}

export { InvalidCredentialsError, PermissionsError };
