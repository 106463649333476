import { fetchBurst } from "@/helpers/api";
import { Burst, SingleBurst } from "@/interfaces/Burst";
import { Temporal } from "@js-temporal/polyfill";
import { getUserTimeZone } from "./store";

//This is an object where each key is `${SensorName} ${JSON.stringify(SensorTags)}`, and each value is null
type PlotableObject = {
  x: Date[];
  y: number[];
};

type PlotableAxes = {
  X: PlotableObject;
  Y: PlotableObject;
  Z: PlotableObject;
};

type PlotableData = {
  SensorName: string;
  SampleRate: number;
  data: PlotableAxes;
};

const truncate = (text: string, length: number): string => {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
};

const fetchBurstDataArray = async (bursts: Burst[]) => {
  const burstData = await Promise.all(
    bursts.map(async (burst) => await fetchBurst(String(burst.BurstID)))
  );
  return burstData;
};

//This is for testing. Generates some burst data
const generateBurstData = () => {
  const burstData: SingleBurst = {
    BatchName: "P4",
    BurstID: 100,
    Duration: "100",
    NSamples: 100,
    TaskRuleID: null,
    BatchTags: {
      Rig: "sierra",
      Aggregator: "amelia",
      Customer: "ge-lubbock",
      Site: "lubbock",
    },
    BatchID: 9919,
    Datatype: "acceleration",
    SensorName: "A3",
    SensorTags: {
      Blade: "A",
      Location: "3",
      NodeAddress: "13",
      NodeMac: "8c:aa:b5:b9:b0:f0",
      NodeName: "A3",
      Units: "g",
      Aggregator: "amelia",
      Customer: "ge-lubbock",
      Site: "lubbock",
    },
    StartTime: "2021-09-07T14:30:00+01:00",
    SampleRate: 62.5,
    AccRange: 8,
    Data: { X: [], Y: [], Z: [] },
  };

  burstData.Data.X = Array.from(Array(37500), (v, i) => Math.sin(i * Math.PI));
  burstData.Data.Y = Array.from(
    Array(37500),
    (v, i) =>
      Math.cos(0.0001 * i * Math.PI) +
      0.1 * Math.cos(0.0055 * i * Math.PI + Math.PI / 3) +
      Math.cos(0.00043 * i * Math.PI + Math.PI / 2) +
      Math.cos(0.0027 * i * Math.PI + Math.PI / 2.5)
  );
  burstData.Data.Z = Array.from(Array(37500), (v, i) => Math.sin(i * Math.PI));
  return [burstData];
};

const getColour = async (
  text: string,
  minLightness = 40,
  maxLightness = 80,
  minSaturation = 30,
  maxSaturation = 100
) => {
  const hash = await window.crypto.subtle.digest(
    "SHA-1",
    new TextEncoder().encode(text)
  );
  const hashNumber = Number(new Uint8Array(hash).join("").slice(16));

  return `hsl(${hashNumber % 360}, ${
    (hashNumber % (maxSaturation - minSaturation)) + minSaturation
  }%, ${(hashNumber % (maxLightness - minLightness)) + minLightness}%)`;
};

const groupBursts = (burstArray: SingleBurst[]): [PlotableData[], boolean] => {
  // Don't process if an array of bursts isn't given
  if (!burstArray.length) {
    return [[], false];
  }
  //First sort the burstArray by the StartDate
  const sortedBurstArray = burstArray.sort((a, b) =>
    Temporal.Instant.compare(a.StartTime, b.StartTime)
  );

  //Go through all of the data, get it ready for plotting
  const allOfTheDataTimestamped = sortedBurstArray.map(
    ({ SensorName, SensorTags, StartTime, SampleRate, Data }) => ({
      [JSON.stringify({
        SensorName: SensorName,
        SampleRate: SampleRate,
        SensorTags: SensorTags,
      })]: formatBurstForPlot(StartTime, SampleRate, Data),
    })
  );

  //Check if selected bursts cover DST boundary
  const offsets = sortedBurstArray.map(
    ({ StartTime }) =>
      Temporal.Instant.from(StartTime).toZonedDateTimeISO(
        getUserTimeZone().value
      ).offset
  );
  const crossesBoundary = !offsets.every((val, i, arr) => val === arr[0]);

  //The key is `${SensorName} ${JSON.stringify(SensorTags)}` because I don't have access to the sensor ID
  const keys = allOfTheDataTimestamped.map((object) => Object.keys(object)[0]);

  //This is the object to return
  const setObject: Record<string, null | PlotableAxes> = Array.from(
    new Set(keys)
  )
    .map((item) => ({ [item]: null }))
    .reduce((prev, current) => ({ ...prev, ...current }));

  const mergePlotableObjects = (
    object1: PlotableObject,
    object2: PlotableObject
  ) => ({
    x: object1.x.concat(object2.x),
    y: object1.y.concat(object2.y),
  });

  const mergePlotableAxes = (object1: PlotableAxes, object2: PlotableAxes) => ({
    X: mergePlotableObjects(object1.X, object2.X),
    Y: mergePlotableObjects(object1.Y, object2.Y),
    Z: mergePlotableObjects(object1.Z, object2.Z),
  });

  allOfTheDataTimestamped.forEach((value) => {
    const key = Object.keys(value)[0];
    const currentObject = setObject[key];
    currentObject === null
      ? (setObject[key] = value[key])
      : (setObject[key] = mergePlotableAxes(currentObject, value[key]));
  });

  const withValuesBack = Object.entries(setObject).map(
    ([uniqueName, plotable]) => {
      const sensorInfo = JSON.parse(uniqueName);
      return {
        SensorName: sensorInfo.SensorName,
        SampleRate: sensorInfo.SampleRate,
        data: plotable,
      } as PlotableData;
    }
  );
  return [withValuesBack, crossesBoundary];
};

const stripTimezoneForDateObject = (time: string) =>
  Temporal.Instant.from(time)
    .toZonedDateTimeISO(getUserTimeZone().value)
    .toPlainDateTime()
    .toZonedDateTime(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .epochMilliseconds;

const formatBurstForPlot = (
  StartTime: string,
  SampleRate: number,
  Data: Record<string, number[]>
) => {
  const startTime = stripTimezoneForDateObject(StartTime);
  const getTime = (y: number, i: number) =>
    new Date(startTime + i * (1000.0 / SampleRate));
  const getPoint = (y: number) => y;
  const timeSeries = {
    X: {
      x: Data.X.map(getTime),
      y: Data.X.map(getPoint),
    },
    Y: {
      x: Data.Y.map(getTime),
      y: Data.Y.map(getPoint),
    },
    Z: {
      x: Data.Z.map(getTime),
      y: Data.Z.map(getPoint),
    },
  };
  return timeSeries;
};

const downloadBurst = async (burst: Burst) => {
  const burstData = await fetchBurst(String(burst.BurstID));
  // Note that the CSV file contains data from all bursts in this batch.
  // We have to retrieve each burst separately from the server and then combine
  // them into a single CSV file.
  const startTime = Temporal.Instant.from(burstData.StartTime);

  const getTime = (i: number) =>
    startTime.add({ milliseconds: i * (1000.0 / burst.SampleRate) }).toString();

  const csv_data = [burstData];

  // Start with the column headers
  let csv_contents = "t, ";
  csv_data.map((burst) => {
    const sensorName =
      burst.SensorName + (burst.SensorTags.NodeAddress !== undefined)
        ? ` (${burst.SensorTags.NodeAddress})`
        : "";
    csv_contents += `${sensorName} X, `;
    csv_contents += `${sensorName} Y, `;
    csv_contents += `${sensorName} Z, `;
  });
  csv_contents += "\n";

  // Now the data - keep creating rows until none of the bursts have data for that row
  let row = 0;
  let last_row_contained_data = true;
  while (last_row_contained_data) {
    last_row_contained_data = false;
    csv_contents += `${getTime(row)}, `;
    csv_data.forEach((burst) => {
      if (row < burst.Data.X.length) {
        last_row_contained_data = true;
        csv_contents += `${burst.Data.X[row]}, ${burst.Data.Y[row]}, ${burst.Data.Z[row]}, `;
      }
    });
    csv_contents += "\n";
    row += 1;
  }

  // Now attach the data to a dummy element and trigger the download
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_contents)
  );
  element.setAttribute("download", `batch_${burst.BatchID}.csv`);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export {
  truncate,
  fetchBurstDataArray,
  generateBurstData,
  getColour,
  formatBurstForPlot,
  groupBursts,
  PlotableData,
  downloadBurst,
};
