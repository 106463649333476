import { Temporal, toTemporalInstant } from "@js-temporal/polyfill";
import humanizeDuration from "humanize-duration";
import { getBrowserTimezone, getLanguage, getUserTimeZone } from "./store";

declare global {
  interface Date {
    toTemporalInstant(this: Date): Temporal.Instant;
  }
}

Date.prototype.toTemporalInstant = toTemporalInstant;

/**
 * @param time string time to format
 * @param showTimezone bool
 * @param showTimeAgo bool
 * @returns Full time formatted based on timezone and language
 */
const formatTime = (time: string, showTimezone=true, showTimeAgo=true): string => {
  const timezone = getUserTimeZone();
  const language = getLanguage();
  
  let output = Temporal.Instant.from(time).toZonedDateTimeISO(timezone.value).toLocaleString(language.value);
  
  if (showTimezone) {
    output += ` ${timezone.value}`;
  }

  if (showTimeAgo) {
    output += ` (${formatTimeSince(time)} ago)`;
  }

  return output;
};

/**
 * @param time Start time as string
 * @param endTime End time as string
 * @returns String giving the number time between the two given times as a formatted string
 */
const formatTimeSince = (
  time: string,
  endTime: string = Temporal.Now.instant().toString()
): string =>
  humanizeDuration(
    Temporal.Instant.from(time)
      .until(Temporal.Instant.from(endTime))
      .total({ unit: "millisecond" }),
    {
      round: true,
      largest: 1,
    }
  );

const getNextDayFromDate = (oldDate: Date): Date => {
  return new Date(
    oldDate
      .toTemporalInstant()
      .toZonedDateTimeISO(getUserTimeZone().value)
      .add({ days: 1 })
      .toInstant()
      .toString()
  );
};

/**
 * @param date Date object, can include time but that will be stripped out
 * @param start Boolean optional set false for end of day
 * @returns String of the epoch seconds to start of day set in Date object
 */
const getEpochSecondsFromDay = (date: Date, start = true): string => {
  return String(
    new Temporal.PlainDate(
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate()
    )
      .toZonedDateTime({
        timeZone: getUserTimeZone().value,
        plainTime: Temporal.PlainTime.from({ hour: 0 }),
      })
      .add({ days: start ? 0 : 1 })
      .toInstant().epochSeconds
  );
};

const timeNowAsJS = (offset = 0): Date =>
  new Date(
    Temporal.Now.zonedDateTimeISO(getUserTimeZone().value)
      .add({ days: offset })
      .toPlainDateTime()
      .toString()
  );

const timeAsJS = (date: string): Date =>
  new Date(
    Temporal.Instant.from(date)
      .toZonedDateTimeISO(getUserTimeZone().value)
      .toPlainDateTime()
      .toString()
  );

const jsTimeToRealEpoch = (input: Date): string =>
  String(
    input
      .toTemporalInstant()
      .toZonedDateTimeISO(getBrowserTimezone())
      .toPlainDateTime()
      .toZonedDateTime(getUserTimeZone().value).epochSeconds
  );

export {
  formatTime,
  formatTimeSince,
  getNextDayFromDate,
  getEpochSecondsFromDay,
  timeNowAsJS,
  jsTimeToRealEpoch,
  timeAsJS,
};
