class FancyWebSocket {
  conn: WebSocket;
  callbacks: Record<string, ((message: any) => void)[]>;

  constructor(url: string) {
    this.callbacks = {};
    this.conn = new WebSocket(url);
    // this.conn.onclose = () => {
    //   this.dispatch("close", null);
    // };
    // this.conn.onopen = () => {
    //   this.dispatch("open", null);
    //   };
    this.conn.onmessage = (evt) => {
      const json = JSON.parse(evt.data);
      this.dispatch(json.event, json.payload);
    };
  }

  dispatch = (event_name: string, message: string | null) => {
    const chain = this.callbacks[event_name];
    if (typeof chain == "undefined") return; // no callbacks for this event
    chain.map((func) => func(message));
  };
  bind = (event_name: string, callback: (data: any) => void) => {
    this.callbacks[event_name] = this.callbacks[event_name] || [];
    this.callbacks[event_name].push(callback);
    return this;
  };
  send = (event_name: string, event_data: string) => {
    const payload = JSON.stringify({ event: event_name, data: event_data });
    this.conn.send(payload); // <-- send JSON data to socket server
    return this;
  };
}

const protocol = window.location.protocol === "https:" ? "wss:" : "ws:";

const socket = new FancyWebSocket(
  `${protocol}//${document.domain}:${location.port}/api/v1/ws`
);

export { socket };
